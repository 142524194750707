import React, { useEffect } from "react";
import { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

export default function Carrusel({ data }) {
  const [imagenes, setImagenes] = useState([]);

  const llenarDatos = () => {
    const items = []; 
    if (data[0].IMG1!=null && data[0].IMG1 != "") {
      items.push({
        src: data[0].IMG1,
        altText: " ",
        caption: " ",
      });
    }
    if (data[0].IMG2!=null && data[0].IMG2 != "") {
      items.push({
        src: data[0].IMG2,
        altText: " ",
        caption: " ",
      });
    }
    if (data[0].IMG3!=null && data[0].IMG3 != "") {
      items.push({
        src: data[0].IMG3,
        altText: " ",
        caption: " ",
      });
    } 
    setImagenes(items);
  };

  useEffect(function(){
    llenarDatos()
  },[]);

  console.log(imagenes)

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === imagenes.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? imagenes.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  
 
  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={imagenes}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />

      {
      imagenes.map((item) => {
    return (
      <CarouselItem key={item.src}>
        <img src={item.src} alt={item.altText} className="d-block w-100" />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  })
  }

      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}
