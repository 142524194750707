import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Backdrop,
  Chip,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DialogoUsuario from "../Dialogos/DialogoVenta";
import Confirmar from "../../Includes/Dialogos/Confirmar";
import { Eliminar } from "../Funciones/Ventas";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import PaymentIcon from "@mui/icons-material/Payment";

export default function Row({ row, getListaAdmin }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [openConfirmElimnar, setOpenConfirmElimnar] = useState(false);
  const [id, setID] = useState(0);
  const open = Boolean(anchorEl);
 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EliminarRegistro = (id) => {
    setOpenLoading(true);
    Eliminar(id)
      .then((resp) => {
        iziToast.success({
          title: "OK",
          message: resp.mensaje,
          position: "topCenter",
        });
        setOpenLoading(false);
        getListaAdmin();
      })
      .catch((resp) => {
        iziToast.error({
          title: "ERROR",
          message: resp.mensaje,
          position: "topCenter",
        });
        setOpenLoading(false);
        alert("error al eliminar");
      });
  };

  return (
    <>
      {openDialog && (
        <DialogoUsuario
          setOpen={setOpenDialog}
          id={id}
          getListaAdmin={getListaAdmin}
        />
      )}

      {openConfirmElimnar && (
        <Confirmar
          Titulo={"¿Estas seguro de emininar?"}
          TextoAyuda="Esta acción no se puede deshacer"
          funcionEjecutar={() => {
            EliminarRegistro(row.ID);
          }}
          setOpen={setOpenConfirmElimnar}
          openLoading={openLoading}
        />
      )}

      <TableRow
        key={row.ID}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="left">
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setID(row.ID);
                  setOpenDialog(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText>Pagar boletos</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </TableCell>

        <TableCell align="left" component="th" scope="row">
          <Chip
            size="small"
            color="primary"
            label={Number(row.ID).toLocaleString("en-US", {
              minimumIntegerDigits: 5,
              useGrouping: false,
            })}
          />
<div className="w-100 mb-2">

</div>
<Chip  className="d-sm-none d-table-cell " 
            size="small"
            color={row.PAGADO ? "success" : row.ESTATUS == 1 ? "info" : "error"}
            label={
              row.PAGADO
                ? "Pagado"
                : row.ESTATUS == 1
                ? "Apartado"
                : "Cancelado"
            }
            onClick={() => {
              if (!row.PAGADO && row.ESTATUS == 1) {
                setID(row.ID);
                setOpenDialog(true);
              }
            }}
          />
        </TableCell>
        <TableCell    className="d-none d-sm-table-cell" align="left" component="th" scope="row">
          <Chip
            size="small"
            color={row.PAGADO ? "success" : row.ESTATUS == 1 ? "info" : "error"}
            label={
              row.PAGADO
                ? "Pagado"
                : row.ESTATUS == 1
                ? "Apartado"
                : "Cancelado"
            }
            onClick={() => {
              if (!row.PAGADO && row.ESTATUS == 1) {
                setID(row.ID);
                setOpenDialog(true);
              }
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={{ fontSize: "11px" }}
        >
          {row.NOMBRE + " " + row.APELLIDO}
        </TableCell>
        <TableCell
          className="d-none d-sm-table-cell"
          align="left"
          style={{ fontSize: "11px" }}
        >
          {row.CORREO}
          <br />
          {row.TELEFONO}
        </TableCell>
        <TableCell
          className="d-none d-sm-table-cell"
          align="left"
          style={{ fontSize: "11px" }}
        >
          {row.MUN}
          <br />
          {row.ESTADO}
        </TableCell>
        <TableCell
          className="d-none d-sm-table-cell"
          align="left"
          style={{ fontSize: "11px" }}
        >
          {row.FECHA_COMPRA}
        </TableCell>

        <TableCell   align="left">
          <Tooltip title={row.BOLETOS} >
            <Chip   size="small" placement="top-start" label={row.NUM_BOLETOS}></Chip>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
