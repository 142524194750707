import "./App.css";
import { Chip, Paper, Stack } from "@mui/material";
import logo from "./Assets/Logo.png";
import { Route, Routes } from "react-router-dom";
import P404 from "./404";
import Header from "./Includes/Header";
import Carrusel from "./Includes/Carrusel";
import Inicio from "./Inicio";
import { AppProvider, AppContext } from "./Contextos/AppContext";
import { useContext } from "react";
import DataSorteo from "./Contextos/DataSorteo";
import Loading from "./admin/Includes/Loading"; 
import StartLoad from "./Includes/StartLoad";
import Compras from "./Compras";
import Metodos from "./Metodos";
import Validador from "./Validador";
function RotesWeb() {
  const { isLoading } = useContext(AppContext);

  return (
    <>
    {
        isLoading ? 
        <StartLoad />
        :
          <Routes>
        <Route path="/" element={<Inicio />}></Route> 
        <Route path="/comprarboleto" element={<Compras />}></Route> 
        <Route path="/metodospago" element={<Metodos />}></Route> 
        <Route path="/validador" element={<Validador />}></Route> 
        <Route path="/*" element={<P404 />} />
      </Routes>
    }
    
    </>
  );
}

export default RotesWeb;
