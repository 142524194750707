import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoBoletoDis } from "../Funciones/Sorteos";

import maquina from "./../Assets/maquina.png";




const BotonRuleta = ({ onClick }) => {
  return (
    <div
      className=" card px-4 py-4 pt-3  pb-2 shadow-lg border-0
    "
    >
      <div className=" p-1 p-md-2">
        <h6 className="mt-2 text-center text-red">
          Deja que la suerte se encarge de hacerte ganar
        </h6>

        <Button
          className="pulsar-sm w-100 m-auto mt-2 btn-primary " 
          variant="contained"
          size="large"
          onClick={onClick}
        >
          <img
            src={maquina}
            className="img-fluid me-3"
            style={{ maxWidth: "30px" }}
          />
          RULETA DE LA SUERTE
        </Button>
        <h6
          className="mt-3 text-center text-muted"
          style={{ fontSize: "10px" }}
        >
          Podras seleccionar numeros al azar
        </h6>
      </div>
    </div>
  );
};

export default BotonRuleta;
