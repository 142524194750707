import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FixedSizeList } from "react-window";
import { AppContext } from "./Contextos/AppContext";
import { getBoletosDisponibles } from "./Funciones/Sorteos";
import fondolineas from "./Assets/fondolineas.png";
import flecha from "./Assets/flecha-hacia-abajo.png";
import iziToast from "izitoast";
import {
  Avatar,
  Backdrop,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
} from "@mui/material";
import { formatMoneda, formatearFecha } from "./Includes/Generales";
import LocalOfferTwoToneIcon from "@mui/icons-material/LocalOfferTwoTone";
import Buscador from "./Includes/Buscador";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMemo } from "react";
import { useRef } from "react";
import Compra from "./Dialogos/Compra";
import Contacto from "./Contacto";
import Paginacion from "./Includes/Paginacion";
import BotonRuleta from "./Includes/BotonRuleta";
import DialogoRuleta from "./Dialogos/DialogoRuleta";
import Footer from "./Includes/Footer";
import Aviso from "./Includes/Aviso";

export default function Compras() {
  const { arrayData } = useContext(AppContext);
  const [data, setData] = useState([]); // Array con 100,000 elementos
  const [loaging, setIsLoading] = useState(false); // Array con 100,000 elementos
  const [mapCompleted, setMapCompleted] = useState(false);
  const [activeButtons, setActiveButtons] = useState([]);
  const [show, setShow] = useState(false);
  const renderedButtonsRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [visisbleDialogoRuleta, setVisisbleDialogoRuleta] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (folio) => {
    if (activeButtons.includes(folio)) {
      setActiveButtons(activeButtons.filter((btn) => btn !== folio));
    } else {
      const newArray = [...activeButtons, folio];
      setActiveButtons(newArray);
    }
  };

  const getBoletosDispon = () => {
    setIsLoading(true);
    getBoletosDisponibles(arrayData.sorteo[0].ID, currentPage)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
        } else {
          let Resp = JSON.parse(resp.objRespuesta);
          setData(Resp);
          setIsLoading(false);
        }
      })
      .catch((resp) => {
        iziToast.error({
          title: "ERROR",
          message: "Ha ocurrido un error, intenta nuevamente ...",
          position: "topCenter",
        });
      });
  };

  useEffect(() => {
    getBoletosDispon();
  }, [currentPage]);

  useEffect(() => {
    setMapCompleted(true);
  }, [data]);

  console.log(arrayData);

  const generarNumeroAleatorio = () => {
    const min = 1;
    const max = 2000;
    const numeroAleatorio = Math.floor(Math.random() * (max - min + 1)) + min;
    return numeroAleatorio;
  };

  const [isMapComplete, setIsMapComplete] = useState(false);

  useEffect(() => {
    if (data.length > 0 && isMapComplete) {
      // Aquí puedes realizar acciones después de terminar de recorrer el array
      console.log("Se ha terminado de recorrer el array");
    }
  }, [data]);

  //  console.log(String(arrayData.sorteo.MAX_BOLETOS).length);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isMapComplete}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {visisbleDialogoRuleta && (
        <DialogoRuleta
          setOpen={setVisisbleDialogoRuleta}
          activeButtons={activeButtons}
          setActiveButtons={setActiveButtons}
        />
      )}
      {show && (
        <Compra
          setOpen={setShow}
          setActiveButtons={setActiveButtons}
          activeButtons={activeButtons}
          getBoletosDispon={getBoletosDispon}
          arrayData={arrayData}
        />
      )}

      {activeButtons.length != 0 && (
        <div className="container-fluid bottom-container shadow-lg border">
          <div className="row">
            <div className="col-12 col-sm-8">
              <div className="container-lg d-flex justify-content-center flex-wrap">
                <div className="container-comprados w-100">
                  {activeButtons.map((row) => {
                    return (
                      <button
                        className={`btn btn-disponible active`}
                        onClick={() => handleClick(row)}
                      >
                        {Number(row).toLocaleString("en-US", {
                          minimumIntegerDigits: 5,
                          useGrouping: false,
                        })}
                      </button>
                    );
                  })}
                </div>
                <center>
                <span className="text-muted w-100 text-center">
                Toca el número para eliminarlo
              </span>
                </center>
              </div>
            </div>
            <div className="col-12 col-sm-4">
              <h4 className="fw-bold text-center mb-0 w-100">
                {activeButtons.length} Números seleccionados
              </h4>
            
              <br />
              <Button
                 
                className="mt-2 pulsar-sm btn-primary" 
                variant="contained"
                onClick={() => {
                  setShow(true);
                }}
              >
                Continuar con la compra
                <ArrowForwardIcon className="ms-2" />
              </Button>

              <Button
                    onClick={() => {
                      setActiveButtons([]);
                    }}
                    fullWidth
                    color="error"
                    variant="text"
                    className="mt-2"
                  >
                    Eliminar todos
                  </Button>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid p-5 container-verde" s>
        <h1 className="fw-bold text-center text-white">
          {arrayData.sorteo[0].NOMBRE}
        </h1>
        <h2 className="fw-  text-center text-white">
          {formatearFecha(arrayData.sorteo[0].FECHA_FIN)}
        </h2>
        <h3 className="fw-  text-center text-white">
          {arrayData.sorteo[0].DESCRIPCION_SORTEO}
        </h3>
      </div>
      <div className="container mt-4 d-flex justify-content-center align-items-center  ">
        <div>
          <img src={flecha} className="pulsar me-2 me-md-4" width={30} />
        </div>
        <div>
          <h1 className="fw-bold text-center mb-0">LISTA DE NÚMEROS ABAJO</h1>
        </div>
        <div>
          <img src={flecha} className="pulsar ms-2 ms-md-4" width={30} />
        </div>
      </div>
      <div className="container mt-5">
        <img src={arrayData.sorteo[0].BANNER} className="img-fluid" />
      </div>

      <div className="container mt-5 d-flex justify-content-center">
        <div
          className="card shadow-lg border-0 p-4 "
          style={{ maxWidth: "531px" }}
        >

          <h2   className="texto-con-sombra text-center"
                        style={{ fontWeight: "800", color: "#ee0000fc" }}>
Compra a partir de:
          </h2>
          {arrayData.precios?.map((row) => {
            return (
              <>
                <div className="d-flex justify-content-star align-items-center mb-4 ">
                  <div>
                    <Avatar 
                      style={{ backgroundColor: "#ee0000fc" }}
                      className="p-4 shadow-lg"
                    >
                      {row.CANTIDAD}
                    </Avatar>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h2 className="mb-0" style={{ fontWeight: "100" }}>
                      <span
                        className="texto-con-sombra"
                        style={{ fontWeight: "800", color: "#ee0000fc" }}
                      > 
                      </span>{" "}
                      {row.CANTIDAD == 1 ? " Boleto por " : " Boletos por "}
                      <span
                        className="texto-con-sombra"
                        style={{ fontWeight: "800", color: "#ee0000fc" }}
                      >
                        {formatMoneda(row.PRECIO)}
                      </span>
                      <span 
                        style={{ fontSize:"13px" }}
                      >
                          {"MXN c/u"}
                      </span>
                   
                    </h2>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <h1 className="fw-bold text-center mb-5 mt-5">
        ELIJE TU NÚMERO DE LA SUERTE
      </h1>
      <div className=" container-lg d-flex flex-wrap justify-content-center container-sm">
        <div className="contenedor-100  card shadow-lg border-0 p-4 mb-4 h-100">
          <div className="d-flex justify-content-star align-items-center  ">
            <div>
              <button className="btn btn-apartado"> 0001</button>
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 style={{ fontSize: "1.5rem" }}> Gris - Vendido </h6>
            </div>
          </div>
          <div className="d-flex justify-content-star align-items-center mt-4">
            <div>
              <button className="btn btn-disponible"> 0001</button>
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 style={{ fontSize: "1.5rem" }}> Blanco - Disponible </h6>
            </div>
          </div>

          <div className="d-flex justify-content-star align-items-center mt-4">
            <div>
              <button className="btn btn-disponible active"> 0001</button>
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 style={{ fontSize: "1.5rem" }}> Rojo - Seleccionado </h6>
            </div>
          </div>
        </div>
        <div className="h-100">
          <Buscador handleClick={handleClick} activeButtons={activeButtons} />
        </div>
      </div>

      <div className=" d-flex justify-content-center mb-5">
        <BotonRuleta
          onClick={() => {
            setVisisbleDialogoRuleta(true);
          }}
        />
      </div>

      <div className="container-lg">
        <Paginacion
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRegistros={arrayData.sorteo[0].MAX_BOLETOS}
        />
      </div>
      {/* */ loaging ? (
        <center>
           <CircularProgress className="mt-5 mb-5" color="error" />
        </center>
       
      ) : (
        
        <div className="container-lg d-flex justify-content-center flex-wrap">
          {data.map((row, index) => {
            return (
              <button
                className={`btn  ${
                  activeButtons.includes(row.FOLIO) ? "active" : ""
                }
                 ${row.ESTATUS == 1 ? " btn-disponible  " : " btn-apartado "}
                `}
                onClick={() => {
                  if (row.ESTATUS == 1) {
                    handleClick(row.FOLIO);
                  }
                }}
              >
                {Number(row.FOLIO).toLocaleString("en-US", {
                  minimumIntegerDigits: 5,
                  useGrouping: false,
                })}
              </button>
            );
          })}
        </div>
      )
    
       }

      <div className="container-lg">
        <Paginacion
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRegistros={arrayData.sorteo[0].MAX_BOLETOS}
        />
      </div>
      <div className="mx-5 " />
      <div className=" d-flex justify-content-center mt-5">
        <BotonRuleta
          onClick={() => {
            setVisisbleDialogoRuleta(true);
          }}
        />
      </div>
      <div className="mx-5 py-5" />

      <Aviso />
      
      <div className="mx5 py-5" />
      <Contacto /> 
      <Footer />
      {
        activeButtons.length!=0 &&
        <>
      <div className="mx-5 py-5" /> 
      <div className="mx-5 py-5" /> 
        </>
      }
    </>
  );
}
