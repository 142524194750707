import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";

import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../admin/Includes/Select";
import { guardarApartado } from "../Funciones/Sorteos";

export default function Compra({
  setOpen,
  setActiveButtons,
  activeButtons,
  getBoletosDispon,
  arrayData,
}) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [fecha, setFecha] = useState(dayjs());

  const [Values, setValues] = useState({
    telefono: "",
    nombre: "",
    apellidos: "",
    municipio: "",
    estado: "",
    correo: "",
  });
  const [Errores, setErrores] = useState({
    telefono: false,
    nombre: false,
    apellidos: false,
    municipio: false,
    estado: false,
    correo: false,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Value != "") {
      if (Name == "telefono") {
        const regex = /^[0-9]*$/; // Expresión regular para aceptar solo números enteros

        if (regex.test(Value)) {
          setValues((prevValues) => ({
            ...prevValues,
            [Name]: Number(Value),
          }));
        }
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          [Name]: Value,
        }));
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [Name]: Value,
      }));
    }

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const getPrecio = () => {
    const boletoEncontrado = arrayData.precios
      .sort((a, b) => b.CANTIDAD - a.CANTIDAD)
      .find((precios) => precios.CANTIDAD <= activeButtons.length);
    console.log(boletoEncontrado);
    if (boletoEncontrado) {
      return boletoEncontrado;
    } else {
      return 0;
    }
  };

 

  console.log(arrayData.precios);

  const GuardarUsusario = () => {
    setIsGuardando(true);
    guardarApartado(Values, Errores, setErrores, activeButtons)
      .then((data) => {
        if (data.estatus == "OK") {
          const mensaje = `
Hola, Aparté boletos para el Sorteo  
*${arrayData.sorteo[0].NOMBRE}*  
—————————————— 
*${activeButtons.length} BOLETOS: ${activeButtons.join(", ")} ,  

Folio: *${data.respuesta}* 
Nombre: *${Values.nombre}* 

Celular: *${Values.telefono}* 

${activeButtons.length} BOLETO POR $${getPrecio()?.PRECIO} PESOS 
—————————————— 

*_EL APARTADO DURA 12 HRS_* 

*CUENTAS DE PAGO AQUÍ:* 
https://rifaditas.com/metodospago

El siguiente paso es enviar foto del comprobante de pago por aquí!'
 
            `;

            alert("Se ha realizado tu apartado, recibiras un correo electronico con la informacion de tus numero apartados, seras redireccionado a whatsApp para proceder con el pago")
          const url = `https://wa.me/${
            arrayData.cnfigs[0].WHATSAPP1
          }?text=${encodeURIComponent(mensaje)}`;
          window.open(url, "_blank");

          getBoletosDispon();
          setActiveButtons([]);
          setIsGuardando(false);
          setOpen(false);
        } else {
          setIsGuardando(false);
        alert("Completa la información requerida para tu apartado")

        }
      })
      .catch((data) => {
        setIsGuardando(false);
        alert("Ha ocurrido un error, verifica tu conexión a internet")
      });
  };

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

 
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">
          Completa tus datos para apartar tus números
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <>
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="row">
                  <div className="col-12 mb-3 mt-3 mt-md-0">
                    <h6 className="text-muted mb-0">
                      Al finalizar te enviaremos al Whatsapp para continuar con
                      tu compra!
                    </h6>
                  </div>
                  <div className="col-12 mb-3 mt-3 mt-md-0">
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="telefono"
                      label="Telefono de WhatsApp"
                      variant="outlined"
                      color="primary"
                      value={Values.telefono}
                      error={Errores.telefono}
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Telefono no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="nombre"
                      label="Nombre(s)"
                      variant="outlined"
                      color="primary"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Nombre no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="apellidos"
                      label="Apellidos"
                      variant="outlined"
                      color="primary"
                      value={Values.apellidos}
                      error={Errores.apellidos}
                      helperText={
                        Errores.apellidos ? "Apellidos no valido" : ""
                      }
                      onChange={handlInputChange}
                    />
                  </div>

                  <div className="col-12  mb-3">
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="municipio"
                      label="Municipio"
                      variant="outlined"
                      color="primary"
                      value={Values.municipio}
                      error={Errores.municipio}
                      helperText={
                        Errores.municipio ? "Municipio no valido" : ""
                      }
                      onChange={handlInputChange}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <Select
                      required
                      titulo="estado"
                      size="small"
                      Name="estado"
                      label="Estado"
                      fullWidth
                      error={Errores.estado}
                      helperText={
                        Errores.estado ? "Selecciona un estado" : ""
                      }
                      ValueSelect={Values.estado}
                      handlInputChange={handlInputChange}
                      opciones={[
                        { value: "Aguascalientes", label: "Aguascalientes" },
                        { value: "Baja California", label: "Baja California" },
                        {
                          value: "Baja California Sur",
                          label: "Baja California Sur",
                        },
                        { value: "Campeche", label: "Campeche" },
                        { value: "Chiapas", label: "Chiapas" },
                        { value: "Chihuahua", label: "Chihuahua" },
                        { value: "Coahuila", label: "Coahuila" },
                        { value: "Colima", label: "Colima" },
                        { value: "Durango", label: "Durango" },
                        {
                          value: "Estado de México",
                          label: "Estado de México",
                        },
                        { value: "Guanajuato", label: "Guanajuato" },
                        { value: "Guerrero", label: "Guerrero" },
                        { value: "Hidalgo", label: "Hidalgo" },
                        { value: "Jalisco", label: "Jalisco" },
                        { value: "Michoacán", label: "Michoacán" },
                        { value: "Morelos", label: "Morelos" },
                        { value: "Nayarit", label: "Nayarit" },
                        { value: "Nuevo León", label: "Nuevo León" },
                        { value: "Oaxaca", label: "Oaxaca" },
                        { value: "Puebla", label: "Puebla" },
                        { value: "Querétaro", label: "Querétaro" },
                        { value: "Quintana Roo", label: "Quintana Roo" },
                        { value: "San Luis Potosí", label: "San Luis Potosí" },
                        { value: "Sinaloa", label: "Sinaloa" },
                        { value: "Sonora", label: "Sonora" },
                        { value: "Tabasco", label: "Tabasco" },
                        { value: "Tamaulipas", label: "Tamaulipas" },
                        { value: "Tlaxcala", label: "Tlaxcala" },
                        { value: "Veracruz", label: "Veracruz" },
                        { value: "Yucatán", label: "Yucatán" },
                        { value: "Zacatecas", label: "Zacatecas" },
                        { value: "Extranjero", label: "Extranjero" },
                      ]}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      color="primary"
                      value={Values.correo}
                      error={Errores.correo}
                      helperText={Errores.correo ? "Correo no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <h5 className="mb-2 text-center" style={{ fontWeight: "100" }}>
                  {activeButtons?.length} boletos x
                  <span
                    className="texto-con-sombra"
                    style={{ fontWeight: "800", color: "#ee0000fc" }}
                  >
                  {" "}${getPrecio()?.PRECIO * activeButtons?.length} MXN
                  </span>{" "}
                  <span style={{fontSize:"15px"}}>
                      (${getPrecio()?.PRECIO})
                  </span>
                
                </h5>
                <div className="container-lg d-flex justify-content-center flex-wrap">
                  <div className="container-comprados2">
                    {activeButtons.map((row) => {
                      return (
                        <button className={`btn btn-disponible active`}>
                          {Number(row).toLocaleString("en-US", {
                            minimumIntegerDigits: 5,
                            useGrouping: false,
                          })}
                        </button>
                      );
                    })}
                  </div>
                </div>

                <div className="col-12 mt-3">
                  <LoadingButton
                    size="large"
                    className="w-100-sm mt-3 mt-sm-0   pulsar-sm btn-primary"
                    loading={IsGuardando}
                    loadingPosition="start"
                    onClick={() => GuardarUsusario()}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando..." : "Apartar número 👉"}
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              className="w-100-sm me-0 me-sm-3"
              size="large"
              variant="outlined"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
