import logo from "../Assets/LogoXl.png";
import solucionesfelix from "../Assets/solucionesfelix.png";

export default function Footer ()
{

return (<>
<div className="container-fluid" style={{backgroundColor:"rgb(255 251 165)"}}>
<div className="text-center p-5">

<img src={logo} className="img-fluid" style={{ maxWidth: "300px" }} />

<h6 className="text-muted mt-5">

© Copyright 2024 rifaditas.com Sitio desarrollado por:    
</h6>
<h6 className="text-muted">

</h6>

<img src={solucionesfelix} className="img-fluid" style={{ maxWidth: "100px" }} />


</div>

</div>

</>)

}


