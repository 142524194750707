import { useContext } from "react";
import { AppContext } from "./Contextos/AppContext";
import Contacto from "./Contacto";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoBoleto } from "./Funciones/Sorteos";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import Footer from "./Includes/Footer";

export default function Validador() {
  const { arrayData } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [intervalo, setIntervalo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showNoencontrado, setNoEncontrado] = useState(false);
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [List, setList] = useState([]);
  // evento para detectar cuando se deja de escribir en el inoput
  const handleChangeBuscador = (event) => {
    clearInterval(intervalo);
    setQuery(event.target.value);
    let id = setInterval(function () {
      clearInterval(id);
      setValueBusqueda(event.target.value);
    }, 1000);
    setIntervalo(id);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clearInterval(intervalo);
      setValueBusqueda(e.target.value);
    }
  };

  const getListaCliente = () => {
    setIsLoading(true);
    setNoEncontrado(false);
    if (ValueBusqueda != "" && ValueBusqueda != undefined) {
      getInfoBoleto(ValueBusqueda)
        .then((resp) => {
          console.log(resp);

          let list = JSON.parse(resp.objRespuesta);
          setList(list);
          setIsLoading(false);
          if (list.length == 0) {
            setNoEncontrado(true);
          } else {
            setNoEncontrado(false);
          }
        })
        .catch((resp) => {
          setList([]);
          setNoEncontrado(true);
          setIsLoading(false);
        });
    } else {
      setList([]);
      setNoEncontrado(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListaCliente();
  }, [ValueBusqueda]);

  const handleDownload_ = (folio) => {
    const container = document.getElementById("mi-contenedor");

    // Obtén la imagen de fondo
    const backgroundImage = window
      .getComputedStyle(container)
      .getPropertyValue("background-image");
    const imageUrl = backgroundImage.slice(4, -1).replace(/"/g, "");

    // Crea una nueva imagen
    const image = new Image();
    image.src = imageUrl;

    // Espera a que la imagen se cargue completamente
    image.onload = () => {
      html2canvas(container)
        .then((canvas) => {
          // Crea un enlace temporal para descargar la imagen
          const link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.download = "Boleto_" + folio + ".png";
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  const handleDownload = (folio) => {
    const container = document.getElementById("mi-contenedor");

    // Espera a que html2canvas termine de renderizar el contenedor
    html2canvas(container, { allowTaint: true, useCORS: true })
      .then((canvas) => {
        // Convierte el canvas a una imagen Base64
        const imageDataURL = canvas.toDataURL();

        // Crea un enlace temporal para descargar la imagen
        const link = document.createElement("a");
        link.href = imageDataURL;
        link.download = "Boleto_" + folio + ".png";
        link.click();
      })
      .catch((error) => {
        console.error("Error al generar la imagen:", error);
      });
  };

  console.log(List);
  return (
    <>
      <h6 className="text-center text-muted mt-3">
        Ingresa el número del voleto que deseas descargar
      </h6>
      <div className="d-flex  justify-content-center w-100">
        <div
          style={{ maxWidth: "600px" }}
          className="contenedor-100 card shadow-lg border-0 mt-3 mb-5 p-4 ms-0 ms-md-4 mb-4 "
        >
          <FormControl fullWidth variant="outlined">
            <InputLabel color="error" htmlFor="outlined-adornment-password">
              Buscar
            </InputLabel>
            <OutlinedInput
              id="Buscar"
              color="error"
              placeholder={"Buscar boleto"}
              value={query}
              onChange={handleChangeBuscador}
              onKeyDown={handleKeyDown}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              label="Buscar"
            />
          </FormControl>

          <Button className="mt-2 mb-4 btn-primary" variant="contained">
            Buscar
          </Button>

          {isLoading && (
            <center>
              <CircularProgress className="mt-5 mb-5" color="success" />
            </center>
          )}
          {showNoencontrado && (
            <Alert severity="error">Boleto no encontrado</Alert>
          )}
          {List.length != 0 && (
            <>
              <div className="">
                <div
                  id="mi-contenedor"
                  style={{
                    position: "relative",
                    width: "320px",
                    height: "640px",
                    overflow: "hidden",
                    backgroundImage: "url(" + arrayData.sorteo[0].BOLETO + ")",
                  }}
                >
                  <p
                    className="position-absolute text-white"
                    style={{
                      left: "119px",
                      fontSize: "30px",
                      top: "128px",
                    }}
                  >
                    {Number(List[0].FOLIO).toLocaleString("en-US", {
                      minimumIntegerDigits: 5,
                      useGrouping: false,
                    })}
                  </p>

                  <p
                    className="position-absolute "
                    style={{
                      left: "19px",
                      fontSize: "14px",
                      top: "225px",
                    }}
                  >
                    {List[0].NOMBRE + " " + List[0].APELLIDO}
                  </p>

                  <p
                    className="position-absolute "
                    style={{
                      left: "19px",
                      fontSize: "14px",
                      top: "284px",
                    }}
                  >
                    {List[0].MUN + ", " + List[0].ESTADO}
                  </p>
                  <p
                    className="position-absolute "
                    style={{
                      left: "19px",
                      fontSize: "14px",
                      top: "345px",
                    }}
                  >
                    {List[0].TELEFONO}
                  </p>
                  <p
                    className="position-absolute "
                    style={{
                      left: "19px",
                      fontSize: "12px",
                      top: "362px",
                    }}
                  >
                    {List[0].CORREO}
                  </p>
                  <p
                    className="position-absolute "
                    style={{
                      left: "19px",
                      fontSize: "15px",
                      top: "408px",
                    }}
                  >
                    {List[0].FECHA_COMPRA}
                  </p>

                  {/*
<img
                    src={arrayData.sorteo[0].BOLETO}
                    className="border shadow"
                  />

                    */}
                </div>
              </div>

              <Button
                onClick={() => {
                  handleDownload(
                    Number(List[0].FOLIO).toLocaleString("en-US", {
                      minimumIntegerDigits: 5,
                      useGrouping: false,
                    })
                  );
                }}
                className="mt-4"
                variant="contained"
                color="success"
              >
                Descargar
              </Button>
            </>
          )}
        </div>
      </div>

      <Contacto />
      <Footer />
    </>
  );
}
