import { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../Assets/Logo.png";
import menu from "../Assets/MENU.png";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import whatsapp from "./../Assets/whatsapp2.png";
import { useContext } from "react";
import { AppContext } from "../Contextos/AppContext";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const { arrayData, isLoading } = useContext(AppContext);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar
        className="py-0 sticky-navbar border"
        sticky={"true"}
        color="light"
        light
        expand="md"
        style={{ height: "75" }}
      >
        <NavbarBrand href="/" className="p-0 ms-0 ms-md-5">
          <img src={logo} className="img-fluid " style={{ maxWidth: "100px" }} />
        </NavbarBrand>

        <div>
          <a
             href={
              "https://api.whatsapp.com/send?phone=" +
              (isLoading ? "0" : arrayData.cnfigs[0].WHATSAPP1)
            }
            class="d-block d-md-none"
            target="_blank"
          >
            <img src={whatsapp} alt="WhatsApp" width="45" height="45" />
          </a>
        </div>

        <IconButton onClick={toggle} className="d-block d-md-none">
          <img src={menu} className="img-fluid" style={{ maxWidth: "50px" }} />
        </IconButton>
        <Collapse className="justify-content-end" isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <Link
              onClick={() => setIsOpen(false)}
              to={"/"}
              style={{ textDecoration: "none" }}
            >
              <NavItem>
                <NavLink
                  className="nav-link-animated fw-bolder text-center"
                  href="/inicio"
                >
                  Inicio
                </NavLink>
              </NavItem>
            </Link>
            <NavItem>
              <NavLink
                className="nav-link-animated fw-bolder text-center"
                href="/#preguntas"
              >
                Preguntas frecuentes
              </NavLink>
            </NavItem>

            <Link
              onClick={() => setIsOpen(false)}
              to={"/validador"}
              style={{ textDecoration: "none" }}
            >
              <NavItem>
                <NavLink
                  className="nav-link-animated fw-bolder text-center"
                  href="/contacto"
                >
                  Validador de boleto
                </NavLink>
              </NavItem>
            </Link>
            <Link
              onClick={() => setIsOpen(false)}
              to={"/metodospago"}
              style={{ textDecoration: "none" }}
            >
              <NavItem>
                <NavLink
                  className="nav-link-animated fw-bolder text-center"
                  href="/contacto"
                >
                  Métodos de pago
                </NavLink>
              </NavItem>
            </Link>
            <Link
              onClick={() => setIsOpen(false)}
              to={"/comprarboleto"}
              style={{ textDecoration: "none" }}
            >
              <NavItem>
                <NavLink
                  className="nav-link-animated fw-bolder text-center"
                  href="/contacto"
                >
                  comprar boleto
                </NavLink>
              </NavItem>
            </Link>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}
