import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Select, MenuItem } from "@mui/material";

const pageSize = 5000; // Tamaño de página: 5000 elementos

const Paginacion = ({
  totalRegistros = 20000,
  setCurrentPage,
  currentPage,
}) => {
  const [data, setData] = useState([]); // Datos totales
  const [totalPages, setTotalPages] = useState(0); // Número total de páginas

  useEffect(() => {
    // Datos de ejemplo (reemplaza con tus propios datos)
    const totalData = [];
    for (let i = 1; i <= totalRegistros; i++) {
      totalData.push(`Elemento ${i}`);
    }

    setData(totalData);

    // Calcula el número total de páginas
    setTotalPages(Math.ceil(totalData.length / pageSize));
  }, []);

  const loadPage = () => {
    // Obtiene los elementos para la página actual
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, data.length);
    const items = data.slice(startIndex, endIndex);

    // Carga los elementos en la vista
    // Aquí debes reemplazar "ItemsComponent" con tu componente de visualización de elementos
    // y pasarle "items" como prop
    return (
      <>
        {startIndex} - {endIndex}
      </>
    );
  };

  const handlePageChange = (event) => {
    setCurrentPage(event.target.value);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Box>
      {/* Renderiza los elementos de la página actual */}
      {
      /*loadPage()
      */}

      {/* Renderiza el selector de página */}

      <div className="d-flex flex-column flex-sm-row justify-content-center my-3">
        <div className="align-self-center">
          <Button
          color="error"
            variant="outlined"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Anterior
          </Button>
        </div>
        <div className="align-self-center">

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center" 
            className="my-3 my-sm-0 mx-3"
          >
            <Typography variant="body1">
              Página
            </Typography>
            <Select value={currentPage} onChange={handlePageChange}>
              {Array.from({ length: totalPages }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body1">de {totalPages}</Typography>
          </Box>
        </div>
        <div className="align-self-center">

          <Button
            variant="outlined"
            color="error"
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Paginacion;
