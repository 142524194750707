import React, { useState, useEffect } from "react";
import CircularWithValueLabel from "./CircularLabel";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CuentaRegresiva = ({ fecha }) => {
  const targetDate = new Date(fecha); // Fecha objetivo para la cuenta regresiva
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Calcula el tiempo restante en días, horas y minutos
  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference > 0) {
      const seconds = Math.floor(difference / 1000) % 60;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0 };
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const value = 0.06;

  console.log(timeRemaining.days / 365);

  return (
    <div className="container">
      <h1 className="text-red fw-bolder text-center mt-5 mb-4">
        Tiempo restante para el sorteo
      </h1>  
      <div className="d-flex justify-content-evenly flex-wrap w-100 mt-5">
        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <div style={{ maxWidth: "120px" }}>
            <CircularProgressbar
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `red`,
                textColor: "red",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
              value={timeRemaining.days / 365}
              maxValue={1}
              text={timeRemaining.days}
            />
          </div>
          <h4 className="fw-bold  mt-3 text-center">Días</h4>
        </div>

        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <div style={{ maxWidth: "120px" }}>
            <CircularProgressbar
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `red`,
                textColor: "red",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
              value={timeRemaining.hours / 24}
              maxValue={1}
              text={timeRemaining.hours}
            />
          </div>
          <h4 className="fw-bold  mt-3 text-center">Horas</h4>
        </div>

        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow"> 
          <div style={{ maxWidth: "120px" }}>
            <CircularProgressbar
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `red`,
                textColor: "red",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
              value={timeRemaining.minutes / 60}
              maxValue={1}
              text={timeRemaining.minutes}
            />
          </div>
          <h4 className="fw-bold  mt-3 text-center">Minutos</h4>
        </div>

        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
         

<div style={{ maxWidth: "120px" }}>
            <CircularProgressbar
              styles={buildStyles({
                rotation: 0.25,
                strokeLinecap: "butt",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `red`,
                textColor: "red",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
              value={timeRemaining.seconds / 60}
              maxValue={1}
              text={timeRemaining.seconds}
            />
          </div>
          <h4 className="fw-bold  mt-3 text-center">Segundos</h4>
        </div>
      </div>
    </div>
  );
};

export default CuentaRegresiva;
