import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../admin/Includes/Select";
import { getBoletosAleatorios, guardarApartado } from "../Funciones/Sorteos";
import machine from "./../Assets/machine.gif";

export default function DialogoRuleta({ setOpen, activeButtons, setActiveButtons }) {
  const [numero, setNumero] = useState("1");
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [List, setList] = useState([]);
  const isMovil = useMediaQuery('(max-width:400px)');

  const handleClose = () => {
    setOpen(false);
  };

  const getNumerosAleatorios = () => {
    setList([]);
    setIsLoading(true);
    getBoletosAleatorios(numero)
      .then((resp) => {
        // console.log(resp);
        let list = JSON.parse(resp.objRespuesta);
        setTimeout(() => {
          setList(list);
          setIsLoading(false);
        }, 3000);
      })
      .catch((resp) => {
        setList([]);
        setIsLoading(false);
      });
  };
 

  const addToCard = () => {

  let newFolios = List.map(objeto => objeto.FOLIO);
    // Verifica si los folios ya están presentes en activeButtons
  const filteredFolios = newFolios.filter((folio) => !activeButtons.includes(folio));

  // Crea un nuevo array que contiene los folios existentes y los nuevos folios
  const newArray = [...activeButtons, ...filteredFolios];

  // Actualiza el estado con el nuevo array de folios
  setActiveButtons(newArray);
  handleClose()
  };

  console.log(List);
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        fullScreen={isMovil}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">
          Deja que la suerte se encarge de hacerte ganar
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <>
            <div className="row">
              <div className="col-12 col-sm-6">
                <h6 className="text-muted mb-3 ">
                  1. Selecciona cuantos boletos quieres generar
                </h6>

                <Select
                  titulo="Número de boletos"
                  size="medium"
                  Name="banco"
                  label="Banco"
                  ValueSelect={numero}
                  handlInputChange={({ target }) => {
                    let val = target.value ? target.value : "";
                    setNumero(val);
                  }}
                  opciones={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                    { value: "7", label: "7" },
                    { value: "8", label: "8" },
                    { value: "9", label: "9" },
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                  ]}
                />

                <h6 className="text-muted my-3 ">
                  2. Presiona el boton generar
                </h6>

                <LoadingButton
                  size="large"
                  className="w-100-sm mt-3 mt-sm-0 btn-primary"
                  loading={IsLoading}
                  loadingPosition="start"
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={() => {
                    getNumerosAleatorios();
                    // setIsLoading(true);
                  }}
                >
                  <span className={IsLoading ? "px-4" : "px-2"}>
                    {IsLoading ? "Generando..." : 
                    <>
                    <ArrowForwardIcon />
                    Generar
                    </>
                    }
                  </span>
                </LoadingButton>
              </div>

              <div className="col-12 col-sm-6">
                {List.length != 0 && (
                  <p className="text-muted">Se han generado {numero} boletos</p>
                )}

                <div className="container-lg d-flex justify-content-center flex-wrap">
                  <div className="container-comprados2">
                    {List.map((row) => {
                      return (
                        <button className={`btn btn-disponible active`}>
                          {Number(row.FOLIO).toLocaleString("en-US", {
                            minimumIntegerDigits: 5,
                            useGrouping: false,
                          })}
                        </button>
                      );
                    })}
                  </div>
                </div>

                <div className="col-12 mt-3">
                  {IsLoading && (
                    <img src={machine} className="img-fluid mb-3" />
                  )}

                  <h6 className="text-muted my-3 ">
                    3. Presiona el botón los quiero para añadir los boletos a tu
                    lista seleccionada
                  </h6>

                  <LoadingButton
                    size="large"
                    className="w-100-sm mt-3 mt-sm-0 btn-primary"
                    loading={IsGuardando}
                    loadingPosition="start"
                    variant="contained"
                    color="success"
                    fullWidth
                    disabled={List.length == 0}
                    onClick={()=>{ 
                      addToCard() 
                    }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando..." : 
                      <>
                      Los quiero  
                    <DownloadDoneIcon />

                      </>
                      }
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <div className="px-3 w-100 text-end ">
            <Button
              className="w-100-sm me-0 me-sm-3"
              size="large"
              variant="outlined"
              onClick={handleClose}
              fullWidth={isMovil}

            >
              Cancelar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
